<template>
    <div class="baskets-page">
        <div class="container-fluid">
            <h1>Koszyki</h1>
            <div class="d-flex justify-content-between mt-3">
                <b-pagination v-if="totalRows>perPage" 
                    v-model="currentPage"
                    :totalRows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
            </div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <b-table v-else :items="myDataProvider" :fields="fields" :filter="filter" :show-empty="true"
                    class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                    :current-page="currentPage" :per-page="perPage"
                    sort-by="id"
                    :sort-desc="true"
                >
                <template #cell(options)="data">
                    <router-link :to="{name: 'AdminOrder',  params: { orderId: data.item.id } }" >
                        <button class="btn btn-secondary" >Zobacz</button>
                    </router-link>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'adminBaskets',
    data() {
        return {
            isLoading: false,
            filter: [],
            perPage: 25,
            currentPage: 1,
            totalRows: 0,
            fields: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Klient', key: 'user.fullname', sortable: true },
                { label: 'Typ', key: 'typename', sortable: true },
                { label: 'Miasto', key: 'address.city', sortable: true },
                { label: 'Komentarz', key: 'address.comment', sortable: true },
                { label: 'Telefon', key: 'address.phone', sortable: true },
                { label: 'Utworzony', key: 'created_at', sortable: true },
                { label: 'Aktualizowany', key: 'updated_at', sortable: true },
                { label: '', key: 'options', sortable: false }
            ]
        }
    },
    computed: {
    },
    methods: {
        myDataProvider(ctx) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            const promise = axios.post('/admin/baskets', {
                "perPage": ctx.perPage,
                "currentPage": ctx.currentPage,
                "sortBy": ctx.sortBy,
                "asc": ctx.sortDesc ? false : true,
                "filter": []
            });

            return promise.then(result => {
                const items = result.data.baskets;
                this.totalRows = result.data.count || 0;
                return items || []
            })
        }
    }
}
</script>

